import { Navigate, Route, Routes } from "react-router-dom";
import Navigation from "./elements/Navigation";
import styled from "styled-components";
import { Leva } from "leva";
import { useCameraControls } from "../../hooks/useCamera";
import { Page } from "./elements";

const StyledHUD = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto min-content;
  gap: 10px;
  padding: 10px;
  pointer-events: none;
  div[class^="leva"] {
    pointer-events: auto;
  }
`;

const HUD = () => {
  const areaKeys = useCameraControls((s) => s.areaKeys);
  return (
    <StyledHUD>
      <Leva hidden={1} />
      <Routes>
        <Route path="/" element={<Navigate to={`/${areaKeys[0]}`} replace />} />
        <Route path="/:page" element={<Page />} />
      </Routes>
      <Navigation />
    </StyledHUD>
  );
};

export default HUD;
