export const Mail = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      {/* <instances.Mailbox
        castShadow
        receiveShadow
        position={[10.7, 0, 5]}
        rotation={[0, Math.PI * 0.5, 0]} 
      />*/}
      {children}
    </group>
  );
};
