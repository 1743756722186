export const Kitchen = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Art_03
        castShadow
        receiveShadow
        position={[-4.9, 1.5, 1]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Art_04
        castShadow
        receiveShadow
        position={[-4.9, 1.5, 3.5]}
        rotation={[0, Math.PI * 0.5, 0]}
        scale={[1.5, 1.5, 1]}
      />
      <instances.Art_05
        castShadow
        receiveShadow
        position={[-4.9, 2.25, 2.2]}
        rotation={[0, Math.PI * 0.5, 0]}
        scale={[1.75, 1.75, 1]}
      />
      <instances.Television_Flat_01A
        castShadow
        receiveShadow
        position={[-4.5, 0.6, 2.2]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Television_Flat_01B
        castShadow
        receiveShadow
        position={[-4.5, 0.6, 2.2]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.TVCabinet_01_Combined
        castShadow
        receiveShadow
        position={[-4.85, 0, 2.2]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Kitchen_Fridge_02
        castShadow
        receiveShadow
        position={[-0.53, 0, 5]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.Kitchen_CounterSink_01
        castShadow
        receiveShadow
        position={[-1.53, 0, 5]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.Oven_01
        castShadow
        receiveShadow
        position={[-2.53, 0, 5]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.Kitchen_Extractor_01
        castShadow
        receiveShadow
        position={[-2.53, 1.825, 5]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.Couch_04
        castShadow
        receiveShadow
        position={[-0.5, 0, 2]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.CoffeeTable_02
        castShadow
        receiveShadow
        position={[-2, 0, 0.5]}
      />
      <instances.PotPlant_02
        castShadow
        receiveShadow
        position={[-0.5, 0.05, 0.5]}
      />
      <instances.Cushion_01
        castShadow
        receiveShadow
        position={[-0.6, 0.8, 1.4]}
        rotation={[Math.PI * 0.3, 0, Math.PI * 1.03]}
      />

      {children}
    </group>
  );
};
