export const RoofBillboard = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Billboard_Roof_01
        castShadow
        receiveShadow
        position={[-1, 0, 2.3]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Billboard_Sign_01
        castShadow
        receiveShadow
        position={[-1, 0, 2.3]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      {children}
    </group>
  );
};
