export const Office = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Desk_01
        castShadow
        receiveShadow
        position={[-4.42, 0, 2.2]}
        rotation={[0, Math.PI * 0.5, 0]}
        scale={[1, 0.77, 1]}
      />
      <instances.Shop_Chair_Top
        castShadow
        receiveShadow
        position={[-3.58, -0.01, 2.35]}
        rotation={[0, Math.PI * -0.5, 0]}
      />
      <instances.Shop_Chair_Bottom
        castShadow
        receiveShadow
        position={[-3.58, 0, 2.35]}
        rotation={[0, Math.PI * -0.5, 0]}
      />
      <instances.Monitor_DoubleA
        castShadow
        receiveShadow
        position={[-4.7, 0.68, 2.45]}
        rotation={[0, Math.PI * 0.55, 0]}
      />
      <instances.Monitor_DoubleB
        castShadow
        receiveShadow
        position={[-4.7, 0.68, 2.45]}
        rotation={[0, Math.PI * 0.55, 0]}
      />
      <instances.Computer_Mouse_01
        castShadow
        receiveShadow
        position={[-4.24, 0.68, 1.95]}
        rotation={[0, Math.PI * 0.4, 0]}
      />
      <instances.Microphone
        castShadow
        receiveShadow
        position={[-4.5, 0.68, 1.475]}
        rotation={[0, Math.PI * 0.35, 0]}
      />
      <instances.Computer_Keyboard_01
        castShadow
        receiveShadow
        position={[-4.2, 0.68, 2.4]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Bed_Double_03
        castShadow
        receiveShadow
        position={[-1.5, 0, 3.55]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.Art_06
        castShadow
        receiveShadow
        position={[-4.9, 2.1, 2.25]}
        rotation={[0, Math.PI * 0.5, 0]}
        scale={[1, 1, 1]}
      />
      <instances.Art_08
        castShadow
        receiveShadow
        position={[-4.9, 2, 1]}
        rotation={[0, Math.PI * 0.5, 0]}
        scale={[2, 2, 1]}
      />
      <instances.Clock_02
        castShadow
        receiveShadow
        position={[-4.9, 2, 3]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Bookshelf_01
        castShadow
        receiveShadow
        position={[-4.7, 0, 4.1]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_01
        castShadow
        receiveShadow
        position={[-4.7, 0.5, 4.3]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_02
        castShadow
        receiveShadow
        position={[-4.7, 0.9, 3.8]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_03
        castShadow
        receiveShadow
        position={[-4.7, 1.85, 4.5]}
        rotation={[Math.PI * -0.5, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_05
        castShadow
        receiveShadow
        position={[-4.7, 1.34, 4.4]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_06
        castShadow
        receiveShadow
        position={[-4.7, 0.9, 4.25]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Book_Group_07
        castShadow
        receiveShadow
        position={[-4.7, 1.34, 3.8]}
        rotation={[0, Math.PI * 0.5, 0]}
      />

      {children}
    </group>
  );
};
