import {
  HouseDoorFill,
  PersonWorkspace,
  Controller,
  FileEarmarkRichtext,
  Mailbox,
  Link45deg,
  HandThumbsUp
} from "react-bootstrap-icons";

import {
  WorkImg,
  ResumeImg,
  LinksImg,
  ThanksImg,
  WelcomeImg,
  ContactImg,
  AboutImg
} from "../assets";

export const areaData = {
  /*************************************************************************
   * WELCOME
   */
  Welcome: {
    name: "Welcome",
    icon: <HouseDoorFill />,
    image: WelcomeImg,
    dialoge: (
      <p>
        Welcome to my playground! I created this site for me to experiment,
        showcase, and host some of my favorite projects. If you're reading this
        now I've given you a special sneak peek because this site isn't quite
        finished. I have a lot more to add and polish in the coming months.
      </p>
    ),
    view: {
      target: [8.943, 10.566, 2.187],
      camera: [9.14, 10.603, 2.185],
      fov: 60
    }
  },
  /*************************************************************************
   * RESUME
   */
  Resume: {
    name: "Resume",
    icon: <FileEarmarkRichtext />,
    image: ResumeImg,
    dialoge: (
      <p>
        As a developer and prototyper, writing down my skills and experience can
        feel secondary to new projects or a portfolio. As I work through the
        best way to represent my career experience, feel free to download my
        latest resume or visit my LinkedIn profile.
      </p>
    ),
    view: {
      target: [-0.433, 11.752, 5.188],
      camera: [-0.353, 11.887, 5.063],
      fov: 60
    }
  },
  /*************************************************************************
   * WORK
   */
  Work: {
    name: "Work",
    icon: <PersonWorkspace />,
    image: WorkImg,
    dialoge: (
      <p>
        My diverse range of projects span from functional prototypes, user
        interaction, and design. I'm a fan of using technology to simplify
        complex ideas and making technology easier on the user. . . . especially
        if I’m the user! Check back soon.
      </p>
    ),
    view: {
      target: [-1.666, 8.084, 0.548],
      camera: [-1.534, 8.206, 0.462],
      fov: 60
    }
  },
  /*************************************************************************
   * ABOUT
   */
  About: {
    name: "About",
    icon: <Controller />,
    image: AboutImg,
    dialoge: (
      <p>
        I'm a dog lover, maker, and coder. I've been called a technical
        innovator by coworkers, but I feel like a digital swiss army knife. I
        can have fun working on anything from running a full stack server on a
        Raspberry Pi to running vector artwork through my laser cutter.
      </p>
    ),
    view: {
      target: [-0.446, 4.793, -1.678],
      camera: [-0.334, 4.879, -1.819],
      fov: 60
    }
  },
  /*************************************************************************
   * LINKS
   */
  Links: {
    name: "Links",
    icon: <Link45deg />,
    image: LinksImg,
    dialoge: (
      <p>
        The internet is an amazing source of tools, information, and ideas.
        Check back soon for a collection of my favorite resources.
      </p>
    ),
    view: {
      target: [3.854, 1.891, -9.071],
      camera: [4.018, 1.934, -9.178],
      fov: 60
    }
  },
  /*************************************************************************
   * ABOUT
   */
  Contact: {
    name: "Contact",
    icon: <Mailbox />,
    image: ContactImg,
    dialoge: (
      <p>
        Feel free to reach out and contact me. I love to 'nerd out' with
        like-minded techies and people passionate about innovation.
        Brett@Brettwilliams.net
      </p>
    ),
    view: {
      target: [13.109, 1.542, 8.345],
      camera: [13.201, 1.627, 8.501],
      fov: 60
    }
  },
  /*************************************************************************
   * THANKS
   */
  Thanks: {
    name: "Thanks",
    icon: <HandThumbsUp />,
    image: ThanksImg,
    dialoge: (
      <p>
        I'm truly grateful for all the people, technology, applications, and
        services I’ve been able to utilize over the years; afterall, no one gets
        to where they are all by themselves.
      </p>
    ),
    view: {
      target: [19.199, 5.139, -13.658],
      camera: [19.396, 5.176, -13.659],
      fov: 60
    }
  },
  /*************************************************************************
   * LOST
   */
  Lost: {
    name: "Lost",
    path: "/Lost",
    hideFromNav: true,
    image: WorkImg,
    dialoge: (
      <p>
        I have no idea what your doing here . . . . whatever you put into the
        address bar isn't right.
      </p>
    ),
    view: {
      target: [5.89611, 29.86204, -19.61281],
      camera: [6.05894, 30.68673, -20.15445],
      fov: 50
    }
  }
};
