import React, { memo } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useCameraControls } from "../../../hooks/useCamera";
import {Dialogue,Content} from "./"

const PageStyled = styled.div`
  &>section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto min-content;
    width: 100%;
    height: 100%;
  }
`;

function Page() {
  // 3D Navigation & triggering
  const { page } = useParams();
  const camera = useCameraControls((s) => s.camera);
  const moveCameraTo = useCameraControls((s) => s.moveCameraTo);
  useEffect(() => moveCameraTo(page), [page, camera, moveCameraTo]);

  // Redirect setup for nonpages
  const setRedirectTo = useCameraControls((s) => s.setRedirectTo);
  const redirectTo = useNavigate();
  useEffect(() => setRedirectTo(redirectTo), [redirectTo]);

  return (
    <PageStyled>
      <section className="Page">
        <Content />
        <Dialogue />
      </section> 
    </PageStyled>
  );
}

export default memo(Page);
