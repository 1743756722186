import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ContentStyled = styled.div`
  height:100%;
  width:100%;
`;

export default function Content() {
  const {page} = useParams()
  return (
    <ContentStyled>
      
    </ContentStyled>
  );
}
