export const Sidewalk = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, -10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, -5]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, 0]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, 5]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, 10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[15, 0, 15]} />
      <instances.Sidewalk_01
        castShadow
        receiveShadow
        position={[-10, 0, -10]}
      />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-10, 0, -5]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-10, 0, 0]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-10, 0, 5]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-10, 0, 10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-10, 0, 15]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[10, 0, -10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[5, 0, -10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[0, 0, -10]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[10, 0, 15]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[5, 0, 15]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[0, 0, 15]} />
      <instances.Sidewalk_01 castShadow receiveShadow position={[-5, 0, 15]} />

      <instances.SubwayEntrance_01
        castShadow
        receiveShadow
        position={[-10, 0, -10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Mailbox
        castShadow
        receiveShadow
        position={[10.7, 0, 5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      {children}
    </group>
  );
};
