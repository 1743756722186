import { useMemo } from "react";
import { useGLTF, Merged } from "@react-three/drei";
import { PortfolioPlaceLibrary } from "../../../../assets";

import {
  Building,
  Fence,
  Kitchen,
  Park,
  Sidewalk,
  Shed,
  Wateredge,
  Workroom,
  Office,
  RoomInterior,
  RoofBillboard,
  RoofPadio,
  LightPole,
  Mail
} from "./sections";

export default function PortfolioPlace(props) {
  const { nodes } = useGLTF(PortfolioPlaceLibrary);
  const instances = useMemo(
    () => ({
      Apartment_Corner_02: nodes.Apartment_Corner_02,
      House_Garage_Floor_01: nodes.House_Garage_Floor_01,
      House_Roof_Angled_Gutter_01: nodes.House_Roof_Angled_Gutter_01,
      InteriorWall_01: nodes.InteriorWall_01,
      InteriorWall_UpperFloorFiller_01: nodes.InteriorWall_UpperFloorFiller_01,
      Planter_01: nodes.Planter_01,
      PotPlant_01: nodes.PotPlant_01,
      Sidewalk_01: nodes.Sidewalk_01,
      Art_03: nodes.Art_03,
      Art_04: nodes.Art_04,
      Art_05: nodes.Art_05,
      Art_08: nodes.Art_08,
      Book_Group_01: nodes.Book_Group_01,
      Book_Group_04: nodes.Book_Group_04,
      Bookshelf_01: nodes.Bookshelf_01,
      CoffeeTable_02: nodes.CoffeeTable_02,
      Cushion_01: nodes.Cushion_01,
      Kitchen_CounterSink_01: nodes.Kitchen_CounterSink_01,
      Table_02: nodes.Table_02,
      Tree_01: nodes.Tree_01,
      WaterEdge_Straight_01: nodes.WaterEdge_Straight_01,
      Shop_Chair_Top: nodes.Shop_Chair_Top,
      Microphone: nodes.Microphone,
      Printer_3D: nodes.Printer_3D,
      WorkShelf: nodes.WorkShelf,
      Apartment_Roof_Corner: nodes.Apartment_Roof_Corner,
      Billboard_Sign_01: nodes.Billboard_Sign_01,
      Billboard_Roof_01: nodes.Billboard_Roof_01,
      Apartment_Door_Corner: nodes.Apartment_Door_Corner,
      Couch_01: nodes.Couch_01,
      ExteriorWall_GarageDoor_01: nodes.ExteriorWall_GarageDoor_01,
      Fence_End_01: nodes.Fence_End_01,
      Grass_01: nodes.Grass_01,
      Fence_01: nodes.Fence_01,
      GrassPath_T_01: nodes.GrassPath_T_01,
      GrassPath_Corner_01: nodes.GrassPath_Corner_01,
      House_ExteriorWall_GroundFloor_Corner_01:
        nodes.House_ExteriorWall_GroundFloor_Corner_01,
      House_Roof_End_03: nodes.House_Roof_End_03,
      GrassPath_Straight_01: nodes.GrassPath_Straight_01,
      House_ExteriorWall_GroundFloor_Garage_Window_01:
        nodes.House_ExteriorWall_GroundFloor_Garage_Window_01,
      House_ExteriorWall_GroundFloor_Garage_01:
        nodes.House_ExteriorWall_GroundFloor_Garage_01,
      Planter_02: nodes.Planter_02,
      PicnicTable_01: nodes.PicnicTable_01,
      Interior_Ceiling_01: nodes.Interior_Ceiling_01,
      Interior_Floor_01: nodes.Interior_Floor_01,
      PotPlant_02: nodes.PotPlant_02,
      InteriorWall_Door_02: nodes.InteriorWall_Door_02,
      Playground_Roundabout_01: nodes.Playground_Roundabout_01,
      Bed_Double_03: nodes.Bed_Double_03,
      Playground_Swing_01: nodes.Playground_Swing_01,
      Roof_Access_01: nodes.Roof_Access_01,
      Kitchen_Extractor_01: nodes.Kitchen_Extractor_01,
      LightPole_Lights_01: nodes.LightPole_Lights_01,
      Art_06: nodes.Art_06,
      Rug_03: nodes.Rug_03,
      Television_Flat_01A: nodes.Mesh048,
      Television_Flat_01B: nodes.Mesh048_1,
      Book_Group_02: nodes.Book_Group_02,
      Book_Group_03: nodes.Book_Group_03,
      Book_Group_05: nodes.Book_Group_05,
      Book_Group_06: nodes.Book_Group_06,
      Book_Group_07: nodes.Book_Group_07,
      Clock_02: nodes.Clock_02,
      Computer_Mouse_01: nodes.Computer_Mouse_01,
      Computer_Keyboard_01: nodes.Computer_Keyboard_01,
      SubwayEntrance_01: nodes.SubwayEntrance_01,
      Computer_Screen_01A: nodes.Mesh033,
      Computer_Screen_01B: nodes.Mesh033_1,
      Computer_Screen_02C: nodes.Mesh050,
      Computer_Screen_02D: nodes.Mesh050_1,
      Couch_04: nodes.Couch_04,
      Vents_Corner_01: nodes.Vents_Corner_01,
      WaterEdge_Straight_02: nodes.WaterEdge_Straight_02,
      Desk_01: nodes.Desk_01,
      Kitchen_Fridge_02: nodes.Kitchen_Fridge_02,
      Mailbox: nodes.Mailbox,
      LightPole_Base_01: nodes.LightPole_Base_01,
      GreenScreen: nodes.GreenScreen,
      Oven_01: nodes.Oven_01,
      LightPole_Lights_02: nodes.LightPole_Lights_02,
      Tree_03: nodes.Tree_03,
      TVCabinet_01_Combined: nodes.TVCabinet_01_Combined,
      Vents_End_01: nodes.Vents_End_01,
      Monitor_DoubleA: nodes.Mesh042,
      Monitor_DoubleB: nodes.Mesh042_1,
      WaterEdge_Corner_02: nodes.WaterEdge_Corner_02,
      WaterEdge_Straight_03: nodes.WaterEdge_Straight_03,
      Camera_Tripod: nodes.Camera_Tripod,
      Tree_02: nodes.Tree_02,
      LightPole_Base: nodes.LightPole_Base,
      Safe: nodes.Safe,
      Safe_Door_01: nodes.Safe_Door_01,
      Shop_Chair_Bottom: nodes.Shop_Chair_Bottom,
      Rolling_TVA: nodes.Mesh041,
      Rolling_TVB: nodes.Mesh041_1,
      WaterTower: nodes.WaterTower,
      ToolBoard: nodes.ToolBoard,
      Camera_DSLR: nodes.Camera_DSLR,
      Workbench: nodes.Workbench,
      Music_Drums_Kit: nodes.Music_Drums_Kit
    }),
    [nodes]
  );
  return (
    <Merged meshes={instances} dispose={null} castShadow receiveShadow>
      {(instances) => {
        return (
          <group name="PortfolioPlace" {...props}>
            <Building instances={instances} name="Building">
              <RoofBillboard
                instances={instances}
                name="Billboard"
                position={[0, 9.5, 0]}
              />
              <RoofPadio
                instances={instances}
                name="Building"
                position={[0, 9.5, 0]}
              />
              <RoomInterior
                instances={instances}
                name="Building"
                position={[0, 6, 0]}
              >
                <Office instances={instances} name="Kitchen" />
              </RoomInterior>
              <RoomInterior
                instances={instances}
                name="Building"
                position={[0, 3, 0]}
              >
                <Kitchen instances={instances} name="Kitchen" />
              </RoomInterior>
            </Building>
            <Shed instances={instances} name="Sidewalk" position={[0, 0.65, 0]}>
              <Workroom instances={instances} name="Workroom" />
            </Shed>
            <Fence instances={instances} name="Fence" />
            <Park instances={instances} name="Park" />
            <Sidewalk instances={instances} name="Sidewalk" />
            <Mail />
            <Wateredge instances={instances} name="Wateredge" />
            <LightPole instances={instances} name="Wateredge" />
          </group>
        );
      }}
    </Merged>
  );
}
