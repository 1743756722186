export const RoofPadio = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Couch_01
        castShadow
        receiveShadow
        position={[-1.5, 0, 7]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Planter_01
        castShadow
        receiveShadow
        position={[-0.5, 0, 7]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Couch_01
        castShadow
        receiveShadow
        position={[-3, 0, 8.5]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Planter_01 castShadow receiveShadow position={[-3, 0, 9.3]} />
      <instances.Table_02 castShadow receiveShadow position={[-3, 0, 7]} />
      <instances.PotPlant_02
        castShadow
        receiveShadow
        position={[-0.5, 0, 9.3]}
      />
      <instances.Planter_02 castShadow receiveShadow position={[-3, 0, 5.3]} />
      {children}
    </group>
  );
};
