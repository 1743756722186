import React, { useRef, memo, useEffect, useCallback } from "react";
import { OrbitControls, PerspectiveCamera, Sphere } from "@react-three/drei";
import { button, useControls } from "leva";
import { useThree } from "@react-three/fiber";
import { useSpring, a } from "@react-spring/three";
import { Vector3 } from "three";
import { areaData } from "../data/areaData";

import create from "zustand";

//// Areas
const areas = areaData;
const areaIndex = 0;
const areaKeys = Object.keys(areas);
const areaCurrent = areas[areaKeys[areaIndex]];

export const useCameraControls = create((set, get) => ({
  /////////////////////////////////////////////////////////////////////////////////////
  // Three Objects
  /////////////////////////////////////////////////////////////////////////////////////
  gl: null,
  scene: null,
  camera: null,
  orbitControl: null,
  setThree: ({ gl, scene, camera, orbitControl }) => {
    set((s) => ({ gl, scene, camera, orbitControl }));
  },
  /////////////////////////////////////////////////////////////////////////////////////
  // 3D model Libararies
  /////////////////////////////////////////////////////////////////////////////////////
  redirectTo: null,
  setRedirectTo: (redirectTo) => set((s) => ({ redirectTo })),
  /////////////////////////////////////////////////////////////////////////////////////
  // 3D model Libararies
  /////////////////////////////////////////////////////////////////////////////////////
  areas,
  areaKeys,
  areaIndex,
  areaCurrent,
  // Locking orbit controls during animation
  orbitEnabled: true,
  setOrbitEnabled: (orbitEnabled) => set((s) => ({ orbitEnabled })),
  // React Spring API
  springAPI: null,
  setSpringAPI: (springAPI) => set((s) => ({ springAPI })),
  // Main Function: Move Camera To
  moveCameraTo: (key) => {
    const { areas, springAPI, orbitControl, camera, redirectTo } = get();
    if ( !camera || !orbitControl.current ) return;
    if ( !areas[key] ){
       key = 'Lost'
       redirectTo('/Lost')
    } 
    const a = areas[key].view;
    const { position, fov } = camera;
    const { target } = orbitControl.current;

    springAPI.set({
      springCameraPos: [position.x, position.y, position.z],
      springTargetPos: [target.x, target.y, target.z],
      springFOV: fov
    });

    springAPI.start({
      springCameraPos: a.camera,
      springTargetPos: a.target,
      springFOV: a.fov
    });

    set((s) => ({ areaCurrent: areas[key] }));
  },
  // Buttons for going to area
  BtnGoToArea: (key) => {
    return useCallback(() => {
      const { moveCameraTo } = get();
      moveCameraTo(key);
    }, [key]);
  },
  // Utility
  copyTextToClipboard: (text) => {
    var textArea = document.createElement("textarea");
    textArea.style.opacity = 0;
    textArea.style.pointerEvents = "none";
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Copying text command was " + msg);
      if (successful) console.log(text);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  },
  saveCameraPos: () => {
    const { copyTextToClipboard, camera } = get();
    const C = {
      x: parseFloat(camera.position.x.toFixed(3)),
      y: parseFloat(camera.position.y.toFixed(3)),
      z: parseFloat(camera.position.z.toFixed(3)),
      f: camera.fov
    };

    var dist = 0.2;
    var cwd = new Vector3();
    camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(camera.position);

    const T = {
      x: parseFloat(cwd.x.toFixed(3)),
      y: parseFloat(cwd.y.toFixed(3)),
      z: parseFloat(cwd.z.toFixed(3))
    };

    const copyText = `
      target:[${T.x},${T.y},${T.z}],
      camera:[${C.x},${C.y},${C.z}],
      fov:${C.f}
    `;
    copyTextToClipboard(copyText);
  },

}));

const CameraController = () => {
  const myCamera = useRef();
  const orbitControl = useRef();

  const setThree = useCameraControls((state) => state.setThree);
  setThree({ ...useThree(), orbitControl });

  const view = useCameraControls((state) => state.areaCurrent.view);
  const orbitEnabled = useCameraControls((state) => state.orbitEnabled);
  const setOrbitEnabled = useCameraControls((state) => state.setOrbitEnabled);

  const cameraMotion = {
    mass: 1,
    tension: 140,
    friction: 50,
    clamp: false,
    precision: 0.0001
  };

  const [
    { springCameraPos, springTargetPos, springFOV },
    springAPI
  ] = useSpring(() => ({
    springTargetPos: view.target,
    springCameraPos: view.camera,
    springFOV: view.fov,
    config: cameraMotion,
    onStart: () => setOrbitEnabled(false),
    onRest: () => setOrbitEnabled(true),
    onChange: () => {
      orbitControl.current.update();
      myCamera.current.updateProjectionMatrix();
    }
  }));

  const setSpringAPI = useCameraControls((state) => state.setSpringAPI);
  useEffect(() => setSpringAPI(springAPI), [springAPI, setSpringAPI]);

  const saveCameraPos = useCameraControls((state) => state.saveCameraPos);
  useControls({
    "Copy Camera vars to Clipboard": button(() => saveCameraPos())
  });

  const AOrbit = a(OrbitControls);
  const APerspectiveCamera = a(PerspectiveCamera);
  const ASphere = a(Sphere);

  const { TargetHelper } = useControls({ TargetHelper: false });

  return (
    <>
      <APerspectiveCamera
        ref={myCamera}
        position={springCameraPos}
        fov={springFOV}
        makeDefault
      />
      <AOrbit
        ref={orbitControl}
        camera={myCamera.current}
        enabled={orbitEnabled}
        target={springTargetPos}
      />
      <ASphere
        position={springTargetPos}
        args={[0.01, 10, 10]}
        visible={TargetHelper}
      >
        <meshBasicMaterial
          attach="material"
          color="hotpink"
          transparent
          opacity={0.5}
        />
      </ASphere>
    </>
  );
};

export default memo(CameraController);
