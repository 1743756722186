//https://codesandbox.io/s/water-shader-1b40u?file=/src/App.js

import * as THREE from "three";
import React, { useRef, useMemo, memo } from "react";
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Water } from "three-stdlib";
import {waterNorms} from "../../../assets/"

extend({ Water });

function Lake(props) {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, waterNorms);
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(60, 60), []);

  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x063a55,
      distortionScale: 1,
      fog: false,
      format: gl.encoding
    }),
    [waterNormals, gl.encoding]
  );
  useFrame(
    (state, delta) => (ref.current.material.uniforms.time.value += delta / 9)
  );
  return (
    <water
      name="Lake"
      ref={ref}
      args={[geom, config]}
      {...props}
    />
  );
}

export default memo(Lake);
