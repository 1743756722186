/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useEffect, useMemo, memo } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { Brett } from "../../../assets/";
import { useGraph } from "@react-three/fiber";
import { SkeletonUtils } from "three-stdlib";
import styled from "styled-components";

const Character = ({ action, words, ...props }) => {
  // return null;
  const { scene, materials, animations } = useGLTF(Brett);
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes } = useGraph(clone);
  const { ref, actions } = useAnimations(animations);

  nodes["Newspaper"].visible = action === "sit-crossed";
  nodes["Letter"].visible = action === "mail";
  nodes["VR-Head"].visible = action === "looking-around";

  useEffect(() => {
    actions[action].reset().fadeIn(0.5).play();
    return () => actions[action]?.fadeOut(0.5);
  }, [actions, action]);

  return (
    <group name={`Character ${action}`} ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Brett"
          position={[0, 0.0029, 0.0179]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
          userData={{ name: "Brett" }}
        >
          <primitive object={nodes.root} />
          <skinnedMesh
            castShadow
            receiveShadow
            frustumCulled={false}
            name="Brett-Mesh"
            geometry={nodes["Brett-Mesh"].geometry}
            material={materials.Brett}
            skeleton={nodes["Brett-Mesh"].skeleton}
            userData={{ name: "Brett-Mesh" }}
          />
        </group>
      </group>
      {words && (
        <Html
          scale={1}
          rotation={[0, 0, 0]}
          position={[0.1, 2, 0]}
          transform
          occlude
          onClick={() => console.log("clicked")}
        >
          <SpeechBubble>
            <div>{words}</div>
          </SpeechBubble>
        </Html>
      )}
    </group>
  );
}

const SpeechBubble = styled.div`
  backface-visibility: hidden;
  font-family: sans-serif;
  position: relative;
  & * {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  & {
  }
  & p {
    margin: 0;
    padding: 0;
  }
  & > div {
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: sans-serif;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.25rem;
    text-align: center;
    border: 0.5px solid black;
  }
  & > div::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 5px;
    border-width: 0 0 6px 6px;
    border-style: solid;
    border-color: transparent #fff;
    display: block;
    width: 0;
  }
  & > div::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 4px;
    border-width: 0 0 8px 8px;
    border-style: solid;
    border-color: transparent #000;
    display: block;
    width: 0;
  }
`;

export default memo(Character);

useGLTF.preload(Brett);