import React, { memo } from "react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { nx,nz,px,py,pz } from "../../../assets/"

import { Plane } from "@react-three/drei";

function BGCube(props) {
  const cubeMap = [
    useLoader(TextureLoader, nx),
    useLoader(TextureLoader, px),
    useLoader(TextureLoader, py),
    useLoader(TextureLoader, nz),
    useLoader(TextureLoader, pz)
  ];

  return (
    <group name="BGCube" {...props}>
      <Plane
        args={[2, 2]}
        scale={30}
        position={[-30, 0, 0]}
        rotation-y={Math.PI * -1.5}
      >
        <meshBasicMaterial map={cubeMap[0]} attach="material" />
      </Plane>
      <Plane
        args={[2, 2]}
        scale={30}
        position={[30, 0, 0]}
        rotation-y={Math.PI * 1.5}
      >
        <meshBasicMaterial map={cubeMap[1]} attach="material" />
      </Plane>
      <Plane
        args={[2, 2]}
        scale={30}
        position={[0, 30, 0]}
        rotation-x={Math.PI * -1.5}
      >
        <meshBasicMaterial map={cubeMap[2]} attach="material" />
      </Plane>
      <Plane
        args={[2, 2]}
        scale={30}
        position={[0, 0, 30]}
        rotation-y={Math.PI}
      >
        <meshBasicMaterial map={cubeMap[3]} attach="material" />
      </Plane>

      <Plane args={[2, 2]} scale={30} position={[0, 0, -30]}>
        <meshBasicMaterial map={cubeMap[4]} attach="material" />
      </Plane>
    </group>
  );
}

export default memo(BGCube);
