import { memo, useEffect, useRef } from "react";
import { useControls, folder } from "leva";
import { CameraHelper } from "three";
import { useHelper } from "@react-three/drei";

function Lights() {
  const {
    dPosition,
    dIntensity,
    dHelper,
    color2,
    color1,
    hIntensity,
    dDepth,
    dHeight,
    dWidth
  } = useControls("Lights", {
    directional: folder({
      dPosition: { value: { x: 11, y: 25, z: -10 }, label: "position" },
      dDepth: 50,
      dWidth: 20,
      dHeight: 20,
      dIntensity: { value: 0.75, label: "intensity" },
      dHelper: { value: false, label: "helper" }
    }),
    hemisphere: folder({
      color1: "#fff",
      color2: "#cbc2b2",
      hIntensity: 1
    })
  });
  const dLight = useRef();
  const dCamera = useRef();
  useEffect(() => {
    if (dLight.current) {
      dCamera.current = dHelper ? dLight.current.shadow.camera : null;
    }
  }, [dLight, dHelper]);
  useHelper(dCamera, CameraHelper, 1, "hotpink");

  return (
    <group name="Lights">
      <hemisphereLight
        color={color1}
        groundColor={color2}
        intensity={hIntensity}
      />
      <directionalLight
        ref={dLight}
        intensity={dIntensity}
        position={[dPosition.x, dPosition.y, dPosition.z]}
        castShadow
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        shadow-camera-far={dDepth}
        shadow-camera-left={-dWidth}
        shadow-camera-right={dWidth}
        shadow-camera-top={dHeight}
        shadow-camera-bottom={-dHeight}
        shadow-bias={-0.005}
        shadow-radius={10}
      />
    </group>
  );
}

export default memo(Lights);
