import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTransition, config, animated as a } from "react-spring";
import { useCameraControls } from "../../../hooks/useCamera";

function Dialogue(props) {
  const { page } = useParams();
  const areas = useCameraControls((s) => s.areas);

  const offStage = {
    x: "1%",
    y: "75%",
    y2: "4%",
    x3: "-10%",
    y3: "26.5%",
    opacity: 0,
    transform: "translate(1%, 10%)"
  };

  const onStage = {
    x: "1%",
    y: "15%",
    y2: "0%",
    x3: "10%",
    y3: "26.5%",
    opacity: 1,
    transform: "translate(1%, 10%)"
  };

  const transitions = useTransition(page, {
    from: offStage,
    enter: onStage,
    leave: offStage,
    trail: 1500,
    config: { mass: 1, tension: 120, friction: 14 }
  });

  return transitions(({ x, y, y2, x3, y3, opacity }, page) => {
    if (!areas[page]) page = "Lost";
    return (
      <StyledDialogue as={a.div} style={{ opacity, y: y2 }}>
        <a.img
          style={{ x: x3, y: y3 }}
          src={areas[page].image}
          alt="Placeholder"
        />
        <a.h2 style={{ x, y }}>{areas[page].name}</a.h2>
        <a.h1 style={{ x, y }}>{areas[page].name}</a.h1>
        <div>{areas[page].dialoge}</div>
      </StyledDialogue>
    );
  });
}

export default memo(Dialogue);

const StyledDialogue = styled.section`
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-bottom: clamp(0.25rem, 2vw, 1rem);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 45px auto;
  position: relative;
  align-items: end;

  & > h1,
  & > h2 {
    grid-column: 1/-1;
    grid-row: 1;
    font-family: "Luckiest Guy", Helvetica, sans-serif;
    font-size: 3rem;
    font-weight: 100;
    margin-bottom: 0px;
    line-height: 0.55;
    font-size: clamp(2rem, 6vw, 3rem);
    display: flex;
    justify-content: start;
    align-items: flex-end;
    margin-left: 1%;
    z-index: 1;
  }

  & > h1 {
    z-index: 1;
    color: white;
  }

  & > h2 {
    z-index: -2;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 10px;
    -webkit-text-stroke-color: black;
  }

  & img {
    grid-column: 2;
    grid-row: 1;
    width: 55vw;
    max-width: 250px;
    justify-self: end;
    transform: translate(10%, 26.5%);
    z-index: 3;
  }
  & div {
    display: grid;
    align-items: start;
    grid-column: 1/-1;
    height: 100%;
    border: 4px solid black;
    padding: 19px;
    position: relative;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 10px;
    font-size: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
    /* clamp(0.75rem, 4vw, 1rem);*/
    background-color: white;
    pointer-events: auto;

    p {
      margin: 0;
      padding: 0;
    }
  }
`;

/*
--font-size-sm: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
--font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
--font-size-md: clamp(1.25rem, 0.61vw + 1.1rem, 1.58rem);
--font-size-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);
--font-size-xl: clamp(1.95rem, 1.56vw + 1.56rem, 2.81rem);
--font-size-xxl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);
--font-size-xxxl: clamp(3.05rem, 3.54vw + 2.17rem, 5rem);
*/
