export const Park = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Grass_01 castShadow receiveShadow position={[-5, 0, -5]} />
      <instances.Grass_01 castShadow receiveShadow position={[0, 0, -5]} />
      <instances.Grass_01 castShadow receiveShadow position={[5, 0, -5]} />
      <instances.Grass_01 castShadow receiveShadow position={[10, 0, -5]} />
      <instances.Grass_01 castShadow receiveShadow position={[10, 0, 0]} />
      <instances.Grass_01 castShadow receiveShadow position={[5, 0, 10]} />
      <instances.Grass_01 castShadow receiveShadow position={[10, 0, 10]} />
      <instances.GrassPath_Corner_01
        castShadow
        receiveShadow
        position={[0, 0, 0]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.GrassPath_T_01 castShadow receiveShadow position={[0, 0, 0]} />
      <instances.GrassPath_T_01
        castShadow
        receiveShadow
        position={[0, 0, 0]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.GrassPath_Straight_01
        castShadow
        receiveShadow
        position={[-10, 0, 0]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.GrassPath_Straight_01
        castShadow
        receiveShadow
        position={[5, 0, 5]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.PicnicTable_01
        castShadow
        receiveShadow
        position={[3.5, 0, 6]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.PicnicTable_01
        castShadow
        receiveShadow
        position={[7, 0, 7]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Tree_01 castShadow receiveShadow position={[6, 0, 5]} />
      <instances.Tree_02 castShadow receiveShadow position={[3, 0, 8.5]} />
      <instances.Tree_03 castShadow receiveShadow position={[9, 0, 9]} />
      <instances.Playground_Swing_01
        castShadow
        receiveShadow
        position={[8, 0.1, -2]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Playground_Roundabout_01
        castShadow
        receiveShadow
        position={[7, 0.1, -7]}
      />

      {children}
    </group>
  );
};
