export const Shed = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[0, 0, -4]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-2.5, 0, -4]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-5, 0, -4]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-2.5, 0, -9]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-5, 0, -9]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-7.5, 0, -9]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-7.5, 0, -4]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Garage_01
        castShadow
        receiveShadow
        position={[-7.5, 0, -6.5]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Corner_01
        castShadow
        receiveShadow
        position={[-7.5, 0, -4]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Corner_01
        castShadow
        receiveShadow
        position={[-7.5, 0, -9]}
        rotation={[0, 0, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Corner_01
        castShadow
        receiveShadow
        position={[0, 0, -4]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.House_ExteriorWall_GroundFloor_Corner_01
        castShadow
        receiveShadow
        position={[0, 0, -9]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.ExteriorWall_GarageDoor_01
        castShadow
        receiveShadow
        position={[0, 0, -9]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.House_Garage_Floor_01
        castShadow
        receiveShadow
        position={[0, 0, -3.9]}
        scale={[1.5, 1, 1.05]}
      />
      <instances.House_Roof_End_03
        castShadow
        receiveShadow
        position={[0, 0, -4]}
      />
      <instances.House_Roof_End_03
        castShadow
        receiveShadow
        position={[0, 0, -9]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.House_Roof_End_03
        castShadow
        receiveShadow
        position={[-7.5, 0, -4]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.House_Roof_End_03
        castShadow
        receiveShadow
        position={[-7.5, 0, -9]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.House_Roof_End_03
        castShadow
        receiveShadow
        position={[-7.5, 0, -9]}
        rotation={[0, Math.PI, 0]}
      />
      <instances.House_Roof_Angled_Gutter_01
        castShadow
        receiveShadow
        position={[-2.5, 0, -9]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.House_Roof_Angled_Gutter_01
        castShadow
        receiveShadow
        position={[-5, 0, -4]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      {children}
    </group>
  );
};
