export const Workroom = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Rolling_TVA
        castShadow
        receiveShadow
        position={[-4, -0.4, -4.5]}
        rotation={[0, Math.PI * -1.15, 0]}
      />
      <instances.Rolling_TVB
        castShadow
        receiveShadow
        position={[-4, -0.4, -4.5]}
        rotation={[0, Math.PI * -1.15, 0]}
        scale={1.01}
      />
      <instances.GreenScreen
        castShadow
        receiveShadow
        position={[-6.25, -0.4, -7.75]}
        rotation={[0, Math.PI * 0.3, 0]}
      />
      <instances.WorkShelf
        castShadow
        receiveShadow
        position={[-1.5, -0.4, -8.25]}
        rotation={[0, 0, 0]}
      />
      <instances.Workbench
        castShadow
        receiveShadow
        position={[-1.75, -0.4, -4.65]}
      />
      <instances.Printer_3D
        castShadow
        receiveShadow
        position={[-0.75, -0.4 + 0.9, -4.5]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.ToolBoard
        castShadow
        receiveShadow
        position={[-2.25, -0.4 + 2.25, -4]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Camera_DSLR
        castShadow
        receiveShadow
        position={[-5.5, -0.4, -6.5]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Camera_Tripod
        castShadow
        receiveShadow
        position={[-5.5, -0.4, -6.5]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Music_Drums_Kit
        castShadow
        receiveShadow
        position={[-6, -0.4, -5]}
        rotation={[0, Math.PI * 0.7, 0]}
      />
      {children}
    </group>
  );
};
