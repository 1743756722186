export const RoomInterior = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Interior_Floor_01
        castShadow
        receiveShadow
        position={[-2.5, 0.05, 2.5]}
      />
      <instances.Interior_Floor_01
        castShadow
        receiveShadow
        position={[0, 0.05, 2.5]}
      />
      <instances.Interior_Floor_01
        castShadow
        receiveShadow
        position={[-2.5, 0.05, 5]}
      />
      <instances.Interior_Floor_01
        castShadow
        receiveShadow
        position={[0, 0.05, 5]}
      />
      <instances.InteriorWall_01
        castShadow
        receiveShadow
        position={[0, 0, 4.9]}
      />
      <instances.InteriorWall_01
        castShadow
        receiveShadow
        position={[0, 0, 4.9]}
      />
      <instances.InteriorWall_01
        castShadow
        receiveShadow
        position={[-5.1, 0, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.InteriorWall_01
        castShadow
        receiveShadow
        position={[-5.1, 0, 2.5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.InteriorWall_Door_02
        castShadow
        receiveShadow
        position={[-2.5, 0, 4.9]}
        rotation={[0, 0, 0]}
      />
      <instances.InteriorWall_UpperFloorFiller_01
        castShadow
        receiveShadow
        scale={[2, 1, 1]}
        position={[0, 0.05, 0]}
      />
      <instances.InteriorWall_UpperFloorFiller_01
        castShadow
        receiveShadow
        scale={[2, 1, 1]}
        rotation={[0, Math.PI * 0.5, 0]}
        position={[0, 0.05, 0]}
      />
      <instances.InteriorWall_UpperFloorFiller_01
        castShadow
        receiveShadow
        scale={[2, 1, 1]}
        position={[0, 3, 0]}
      />
      <instances.InteriorWall_UpperFloorFiller_01
        castShadow
        receiveShadow
        scale={[2, 1, 1]}
        rotation={[0, Math.PI * 0.5, 0]}
        position={[0, 3, 0]}
      />
      <instances.Interior_Ceiling_01
        castShadow
        receiveShadow
        position={[-2.5, 0, 2.5]}
      />
      <instances.Interior_Ceiling_01
        castShadow
        receiveShadow
        position={[0, 0, 2.5]}
      />
      <instances.Interior_Ceiling_01
        castShadow
        receiveShadow
        position={[-2.5, 0, 5]}
      />
      <instances.Interior_Ceiling_01
        castShadow
        receiveShadow
        position={[0, 0, 5]}
      />

      {children}
    </group>
  );
};
