import { memo } from "react";
import Character from "./Character";

const AllCharacters = (props) => (
  <group name="characters" {...props}>
    <Character
      action={"typing"}
      position={[-3.5, 6.04, 2.3]}
      rotation={[0, Math.PI * 1.5, 0]}
    />
    <Character
      action={"looking-around"}
      position={[-3, 3.1, 3]}
      rotation={[0, Math.PI * -3, 0]}
    />
    <Character
      action={"waving"}
      position={[-0.5, 10.33, 0.5]}
      rotation={[0, Math.PI * -1.5, 0]}
    />
    <Character
      action={"sit-crossed"}
      position={[-1.45, 9.25, 7.2]}
      rotation={[0, Math.PI * -0.5, 0]}
    />
    <Character
      action={"hanging"}
      position={[15.023, 4.1, -13.75]}
      rotation={[0, Math.PI * -1.5, 0]}
    />
    <Character
      action={"mail"}
      position={[11.67, 0, 4.85]}
      rotation={[0, Math.PI * -0.5, 0]}
    />
    <Character
      action={"rubbing-shoulder"}
      position={[-4, 0.25, -6]}
      rotation={[0, Math.PI * -1.6, 0]}
    />
    
    <Character action={"falling"} position={[5.5, 25, -17]} />
  </group>
);

export default memo(AllCharacters)