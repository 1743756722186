import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Preload, Stars } from "@react-three/drei";
import { Perf } from "r3f-perf";

import CameraController from "../../hooks/useCamera";
import {
  PortfolioPlace,
  AllCharacters,
  Lights,
  BGCube,
  Lake,
  Labels,
  Loader
} from "./elements/";

// Scene
export default function Scene3D() {
  return (
    <Canvas shadows dpr={[1, 2]}>
      {/* <Perf />  */}
      <Lights />
      <Suspense fallback={<Loader />}>
        <PortfolioPlace />
        <AllCharacters />
        <BGCube position-y={2.5} rotation-y={Math.PI * 1} />
        <Lake rotation-x={-Math.PI / 2} position={[0, -4, 0]} />
        <Stars />
        {/* <Labels /> */}
        <CameraController />
        <Preload all />
      </Suspense>
    </Canvas>
  );
}
