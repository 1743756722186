export const Fence = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.Fence_01 castShadow receiveShadow position={[10, 0, 10]} />
      <instances.Fence_01 castShadow receiveShadow position={[5, 0, 10]} />
      <instances.Fence_01 castShadow receiveShadow position={[-5, 0, -10]} />
      <instances.Fence_01 castShadow receiveShadow position={[0, 0, -10]} />
      <instances.Fence_01 castShadow receiveShadow position={[5, 0, -10]} />
      <instances.Fence_01 castShadow receiveShadow position={[10, 0, -10]} />

      <instances.Fence_01
        castShadow
        receiveShadow
        position={[10, 0, -10]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Fence_01
        castShadow
        receiveShadow
        position={[10, 0, -5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Fence_01
        castShadow
        receiveShadow
        position={[10, 0, 5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Fence_01
        castShadow
        receiveShadow
        position={[-10, 0, -10]}
        rotation={[0, Math.PI * 0.5, 0]}
      />

      <instances.Fence_End_01
        castShadow
        receiveShadow
        position={[-10, 0, -10]}
      />
      <instances.Fence_End_01
        castShadow
        receiveShadow
        position={[-10, 0, -5]}
      />
      <instances.Fence_End_01
        castShadow
        receiveShadow
        position={[-5, 0, -10]}
      />
      <instances.Fence_End_01 castShadow receiveShadow position={[0, 0, -10]} />
      <instances.Fence_End_01
        castShadow
        receiveShadow
        position={[10, 0, -10]}
      />
      <instances.Fence_End_01 castShadow receiveShadow position={[10, 0, -5]} />
      <instances.Fence_End_01 castShadow receiveShadow position={[10, 0, 0]} />
      <instances.Fence_End_01 castShadow receiveShadow position={[10, 0, 5]} />
      <instances.Fence_End_01 castShadow receiveShadow position={[10, 0, 10]} />
      <instances.Fence_End_01 castShadow receiveShadow position={[5, 0, 10]} />
      <instances.Fence_End_01 castShadow receiveShadow position={[5, 0, -10]} />
      {children}
    </group>
  );
};
