export const Building = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      {/* <group
        name="KitchenCorner"
        position={[-0, 3, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      >
        <instances.Apartment_Corner_02 castShadow receiveShadow />
      </group>
      <group
        name="KitchenCorner"
        position={[-0, 6, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      >
        <instances.Apartment_Corner_02 castShadow receiveShadow />
      </group> */}
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[0, 0, 10]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 0, 10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 0, 0]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[0, 3, 10]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 3, 10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 3, 0]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[0, 6, 10]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 6, 10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Apartment_Corner_02
        castShadow
        receiveShadow
        position={[-10, 6, 0]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Apartment_Roof_Corner
        castShadow
        receiveShadow
        position={[0, 9, 10]}
      />
      <instances.Apartment_Roof_Corner
        castShadow
        receiveShadow
        position={[-10, 9, 10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.Apartment_Roof_Corner
        castShadow
        receiveShadow
        position={[-10, 9, 0]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.Apartment_Roof_Corner
        castShadow
        receiveShadow
        position={[-0, 9, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Apartment_Door_Corner
        castShadow
        receiveShadow
        position={[-0, 0, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterTower castShadow receiveShadow position={[-8, 9.5, 2]} />
      <instances.Roof_Access_01
        castShadow
        receiveShadow
        position={[-8, 9.5, 5.5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Vents_End_01
        castShadow
        receiveShadow
        position={[-6.1, 9.5, 9.25]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.Vents_Corner_01
        castShadow
        receiveShadow
        position={[-6, 9.5, 9.25]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      {children}
    </group>
  );
};
