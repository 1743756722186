import Scene3D from "./components/3D/Scene3D";
import HUD from "./components/UX/HUD"
import styled from "styled-components";
import "./styles.css";

export default function App() {
  return (
    <AppStyled>
      <Scene3D />
      <HUD/>
    </AppStyled>
  );
}

const AppStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto min-content;
  width: 100%;
  height: 100%;
  padding: 5px;
  min-height: -webkit-fill-available;
`;
