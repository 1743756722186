export const LightPole = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.LightPole_Base
        castShadow
        receiveShadow
        position={[15, 0, -15]}
      />
      {children}
    </group>
  );
};
