import { Html, useProgress } from "@react-three/drei";
import { useEffect } from "react";
import styled from "styled-components";

const StyledLoader = styled.div`
  position: fixed;
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-content: center;
  top: 0 !important;
  left: 0 !important;
  background-color: white;
  text-align: center;
  border-radius: 25px;
  transform: translate(-50%, -50%);

  & > div {
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: white;
    border-radius: 25px;
    white-space: nowrap;
    padding: 20px;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  h1,
  h2 {
    margin: 0;
    padding: 0;
  }
`;

export default function Loader() {
  const { progress, loaded, total } = useProgress();

  useEffect(() => {
    console.log("Mounted");
    return () => console.log("Unmounted");
  }, []);
  return (
    <Html>
      <StyledLoader>
        <div>
          <h1>{parseInt(progress, 10)}% Loaded</h1>
          <h2>
            updating {loaded} out of {total}
          </h2>
        </div>
      </StyledLoader>
    </Html>
  );
}
