export const Wateredge = ({ instances, children, ...props }) => {
  return (
    <group {...props}>
      <instances.WaterEdge_Corner_02
        castShadow
        receiveShadow
        position={[-15, 0, -15]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[-10, 0, -15]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[-5, 0, -15]}
      />
      <instances.WaterEdge_Straight_02
        castShadow
        receiveShadow
        position={[0, 0, -15]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[5, 0, -15]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[10, 0, -15]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[15, 0, -15]}
      />
      <instances.WaterEdge_Corner_02
        castShadow
        receiveShadow
        position={[15, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[-15, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[-10, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_02
        castShadow
        receiveShadow
        position={[-5, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[0, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[5, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[10, 0, 15]}
        rotation={[0, Math.PI * 1, 0]}
      />
      <instances.WaterEdge_Corner_02
        castShadow
        receiveShadow
        position={[15, 0, -15]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Corner_02
        castShadow
        receiveShadow
        position={[-15, 0, 15]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[15, 0, 15]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[15, 0, 10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[15, 0, 5]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_02
        castShadow
        receiveShadow
        position={[15, 0, 0]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[15, 0, -5]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[15, 0, -10]}
        rotation={[0, Math.PI * 1.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[-15, 0, 10]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[-15, 0, 5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[-15, 0, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_02
        castShadow
        receiveShadow
        position={[-15, 0, -5]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_01
        castShadow
        receiveShadow
        position={[-15, 0, -10]}
        rotation={[0, Math.PI * 0.5, 0]}
      />
      <instances.WaterEdge_Straight_03
        castShadow
        receiveShadow
        position={[-15, 0, -15]}
        rotation={[0, Math.PI * 0.5, 0]}
      />

      {children}
    </group>
  );
};
